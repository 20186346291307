<template>
  <b-modal
    id="ModalPurchaseCourse"
    :visible="modalVisibility(modalId)"
    :title="`Purchase New Course`"
    @show="onShow()"
    @hidden="resetModal()"
    @ok="onOk"
    size="xl"
    no-close-on-backdrop
    ok-title="Submit"
  >
    <div>
      <b-row>
        <b-col cols="3" class="d-none">
          <base-input
            :disabled="readOnly"
            v-model="form.trainee_course_id"
            label="Course ID"
            :validator="$v.form.trainee_course_id"
            :messages="localMessages"
          ></base-input>
        </b-col>
        <b-col>
          <base-input
            :disabled="readOnly"
            v-model="form.course_name"
            label="Course Name"
            :validator="$v.form.course_name"
            :messages="localMessages"
          ></base-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <base-input
            :disabled="readOnly"
            v-model="form.description"
            label="Description"
            :validator="$v.form.description"
            :messages="localMessages"
          ></base-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <base-input
            type="textarea"
            :disabled="readOnly"
            v-model="form.long_description"
            label="Detail Description"
            :validator="$v.form.long_description"
            :messages="localMessages"
          ></base-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <base-input
            :disabled="readOnly"
            v-model="form.duration"
            label="Course Duration"
            :validator="$v.form.duration"
            :messages="localMessages"
          ></base-input>
        </b-col>
      </b-row>
      <b-row class="mb-6">
        <b-col>
          <label>Modules</label>
          <b-table
            table-variant="light"
            show-empty
            bordered
            striped
            :fields="SelectedCourseModulesFields"
            :items="SelectedCourseModules"
          ></b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <base-input
            :disabled="readOnly"
            v-model="form.unit_course_price"
            label="Price/Seat (MYR)"
            :validator="$v.form.unit_course_price"
            :messages="localMessages"
          ></base-input>
        </b-col>
        <b-col>
          <base-input
            type="number"
            v-model="form.quantity"
            label="Order Quantiy"
            :validator="$v.form.quantity"
            :messages="localMessages"
          ></base-input>
        </b-col>
        <b-col>
          <base-input
            type="select"
            placeholder="Please select"
            :options="PaymentChannelOptions"
            v-model="payment_channel_id"
            label="Payment Method"
            :validator="$v.form.payment_channel_id"
            :messages="localMessages"
          ></base-input>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";
import { required } from "vuelidate/lib/validators";
import SystemHelper from "@/core/services/systemhelper.service";
import {
  CHECKOUT_COMPANY_COURSE,
  GET_COMPANY_TRAINEE_COURSE_LIST,
} from "@/core/services/store/modules/clientadmin/course.module";

export default {
  mixins: [validationMixin],
  components: {},
  data() {
    return {
      readOnly: true,
      modalId: "ModalPurchaseCourse",
      isBusy: true,
      form: {
        trainee_course_id: "",
        unit_course_price: "",
        quantity: "",
      },
      payment_channel_id: 1,
      SelectedCourseModulesFields: [
        { key: "module_name" },
        { key: "description" },
        { key: "category" },
      ],
    };
  },
  validations: {
    form: {
      trainee_course_id: { required },
      unit_course_price: { required },
      quantity: { required },
      course_name: { required },
    },
    payment_channel_id: { required },
  },
  computed: {
    PaymentChannelOptions() {
      const paymentChanel = [
        {
          name: "Offline Payment",
          code: 2,
        },
        {
          name: "Online Payment",
          code: 1,
        },
      ];
      let options = [];
      if (paymentChanel.length) {
        paymentChanel.forEach((paymentChannel) => {
          options.push({
            label: paymentChannel.name,
            value: paymentChannel.code,
          });
        });
      }
      return options;
    },
    SelectedCourseModules() {
      let x = this.form.List_of_Trainee_Module;
      if (Array.isArray(x) && x.length) {
        return x;
      } else {
        return [];
      }
    },
  },
  methods: {
    textApproveStatus(id) {
      let txt;
      switch (id) {
        case 1:
          txt = "Approved";
          break;
        case 2:
          txt = "Pending";
          break;
        case 3:
          txt = "Rejected";
          break;
      }
      return txt;
    },
    onShow() {
      console.log("showing");
      console.log(this.$parent.selectedCourse);
      this.form = this.$parent.selectedCourse;
    },

    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      } else {
        console.log("submit");

        let payload = {
          selected_courses: JSON.stringify([this.form]),
          payment_channel_id: this.payment_channel_id,
        };
        SystemHelper.confirmationDialogHandler(
          { title: "Confirmation", html: "Purchase course?" },
          { action: CHECKOUT_COMPANY_COURSE, payload: payload },
          [
            {
              action: GET_COMPANY_TRAINEE_COURSE_LIST,
              payload: this.defaultPayload,
            },
          ],
          this.modalId
        );
      }
    },
    onOk(bvModalEvent) {
      console.log(bvModalEvent);
      bvModalEvent.preventDefault();

      // trigger submit
      this.onSubmit();
    },
    resetModal() {
      this.payment_channel_id = 1;
      this.$v.form.$reset();
      this.$store.dispatch(SET_MODAL_STATE, null);
    },
  },
};
</script>
