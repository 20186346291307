<template>
  <div>
    <KTCard title="Buy Course">
      <template v-slot:body>
        <b-row v-if="!!tableSettings">
          <b-col cols="6">
            <b-form-group class="m-2" label-size="sm">
              <template v-if="tableSettings.refresh">
                <b-button size="sm" class="mr-2">
                  <i class="fa fa-sync"></i> Refresh
                </b-button>
              </template>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="m-2"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
            >
              <template v-if="tableSettings.filter">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-form-group>
          </b-col>
        </b-row>

        <b-table
          :per-page="tableSettings.perPage"
          :current-page="tableSettings.currentPage"
          :filter="filter"
          striped
          bordered
          show-empty
          hover
          :items="AvailableCourseList"
          :fields="fields"
        >
          <template v-slot:cell(unit_course_price)="row">
            <vue-numeric
              type="string"
              read-only
              :currency="globalCurrency"
              separator=","
              v-bind:precision="2"
              v-model="row.item.unit_course_price"
            ></vue-numeric>
          </template>

          <template v-slot:cell(actions)="row">
            <b-button-group size="sm">
              <b-button
                @click="onPurchaseCourse(row.item)"
                variant="primary"
                size="sm"
              >
                Get Course
              </b-button>
            </b-button-group>
          </template>
        </b-table>

        <b-pagination
          v-if="tableSettings.row > tableSettings.perPage"
          class="pl-0"
          v-model="currentPage"
          :total-rows="tableSettings.rows"
          :per-page="tableSettings.perPage"
        ></b-pagination>
      </template>
    </KTCard>

    <purchase-new-course-modal></purchase-new-course-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import { GET_COMPANY_TRAINEE_COURSE_LIST } from "@/core/services/store/modules/clientadmin/course.module";
import PurchaseNewCourseModal from "@/view/pages/clientadmin/trainingcourse/modals/PurchaseNewCourseModal";
import VueNumeric from "vue-numeric";

export default {
  mixins: [validationMixin],
  components: { PurchaseNewCourseModal, VueNumeric },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Client Admin" },
      { title: "Purchased Course" },
      { title: "Purchase New Course" },
    ]);

    this.$store.dispatch(GET_COMPANY_TRAINEE_COURSE_LIST, this.defaultPayload);
  },
  computed: {
    tableSettings() {
      const list =
        this.$store.state.clientadmin_course.companyTraineeCourseList;
      let perPage = 20;
      let listLength;
      if (Array.isArray(list) && list.length) {
        listLength = list.length;
      } else {
        listLength = 100;
      }
      return {
        row: listLength,
        perPage: perPage,
        refresh: false,
        filter: true,
        pagination: true,
      };
    },
    AvailableCourseList() {
      const list =
        this.$store.state.clientadmin_course.companyTraineeCourseList;
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      modalId: "ModalPurchaseCourse",
      selectedCourse: null,
      filter: "",
      currentPage: 1,
      fields: [
        {
          key: "trainee_course_id",
          label: "Course ID",
          sortable: true,
        },
        {
          key: "course_name",
          label: "Course Name",
          sortable: false,
        },
        {
          key: "unit_course_price",
          label: "Course Price",
          sortable: false,
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-right",
        },
      ],
    };
  },

  methods: {
    onPurchaseCourse(invoice) {
      // assign selected invoice
      this.selectedCourse = JSON.parse(JSON.stringify(invoice));

      // open permission modal
      this.openModal("ModalPurchaseCourse");
    },
  },
};
</script>
